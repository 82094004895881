import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'
import vuetify from './plugins/vuetify';

import Courts from './components/Courts.vue';
import Users from './components/Users.vue';
import UsersByGroup from './components/UsersByGroup.vue';
import Groups from './components/Groups.vue';
import Coupons from './components/Coupons.vue';
import Holes from './components/Holes.vue';
import Recordings from './components/Recordings.vue';

import CourtCountInfoCard from './components/info-cards/CourtCountInfoCard.vue';
import HoleCountInfoCard from './components/info-cards/HoleCountInfoCard.vue';
import RecordingCountInfoCard from './components/info-cards/RecordingCountInfoCard.vue';
import ErrorCountInfoCard from './components/info-cards/ErrorCountInfoCard.vue';

import HoleInfoCard from './components/info-cards/HoleInfoCard.vue';
import LaravelVuePagination from 'laravel-vue-pagination';


Vue.prototype.$http = Axios;
const token = localStorage.getItem('user-token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

Vue.config.productionTip = false

//custom components
Vue.component('users', Users)
Vue.component('users-by-group', UsersByGroup)
Vue.component('groups', Groups)
Vue.component('coupons', Coupons)
Vue.component('courts', Courts)
Vue.component('holes', Holes)
Vue.component('recordings', Recordings)
Vue.component('court-count-info-card', CourtCountInfoCard)
Vue.component('hole-count-info-card', HoleCountInfoCard)
Vue.component('recording-count-info-card', RecordingCountInfoCard)
Vue.component('error-count-info-card', ErrorCountInfoCard)

Vue.component('hole-info-card', HoleInfoCard)
Vue.component('pagination', LaravelVuePagination)

//axios endpoint
Axios.defaults.baseURL = 'https://api.theacechallenge.com';

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
